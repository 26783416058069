
  export const characteristicsData = {
  1: {
    positive: [
      "Natural leaders with strong independence and confidence",
      "Highly charismatic and can easily motivate others",
      "Goal-oriented and determined to achieve success",
    ],
    constructive: [
      "Can be stubborn and sets high expectations",
      "Tends to dominate in relationships and may lose interest quickly if not challenged",
      "Often impatient and expects perfection from themselves and others",
    ],
  },
  2: {
    positive: [
      "Highly creative and imaginative with strong artistic skills",
      "Calm, gentle, and values emotional connections over physical attributes",
      "Spiritual and seeks a deep, mutual understanding in relationships",
    ],
    constructive: [
      "Easily hurt by criticism and emotionally delicate",
      "Tends to get lost in their own world, struggling with practical tasks",
      "Prone to mood swings, which can affect decision-making",
    ],
  },
  3: {
    positive: [
      "Confident and bold with strong willpower and ambition",
      "Energetic and driven, excelling in managing tasks and achieving goals",
      "Natural leaders with excellent managerial skills and discipline",
    ],
    constructive: [
      "Can be bossy and prefers to dominate in both work and relationships",
      "Lacks patience and may struggle with humility",
      "Excessive ambition can lead to stress and strained relationships",
    ],
  },
  4: {
    positive: [
      "Reliable, loyal, and focused on long-term goals",
      "Pragmatic and realistic, with strong intuition and problem-solving skills",
      "Supportive in relationships, offering reliability and strong support to loved ones",
    ],
    constructive: [
      "Prone to sudden bursts of anger, disrupting stability",
      "Can be overly moralistic and impose values on others",
      "Rigid and unyielding, struggling to adapt to different opinions or lifestyles",
    ],
  },
  5: {
    positive: [
      "Dynamic and sociable, with a wide range of interests",
      "Intelligent and inquisitive, constantly seeking new knowledge",
      "Strives for harmony in relationships, valuing emotional stability",
    ],
    constructive: [
      "Unpredictable and restless, easily bored and seeking constant change",
      "Sensitive to words and may struggle with expressing emotions",
      "Prone to disorganization, leading to confusion in tasks",
    ],
  },
  6: {
    positive: [
      "Family-oriented and deeply values relationships",
      "Peacemaker with strong diplomatic skills, resolving conflicts effectively",
      "Responsible and reliable, always fulfilling promises and duties",
    ],
    constructive: [
      "Overly trusting, easily influenced by flattery",
      "Emotionally sensitive and takes criticism deeply to heart",
      "May waste time and energy on people who don’t appreciate their kindness",
    ],
  },
  7: {
    positive: [
      "Intellectually driven with strong analytical skills",
      "Excellent intuition, often trusts first impressions",
      "Prefers working independently, excels in science and research",
    ],
    constructive: [
      "Can be emotionally distant and struggle with expressing feelings",
      "Tends to be self-critical and stubborn",
      "Relationships may suffer if they become too closed off or cynical",
    ],
  },
  8: {
    positive: [
      "Business-minded with excellent financial sense",
      "Creative and unconventional in approach",
      "Strong leader, capable of managing large projects independently",
    ],
    constructive: [
      "Can be overly demanding and critical of others",
      "Prefers complete control, struggles with partnership",
      "May become materialistic, seeking validation through expensive possessions",
    ],
  },
  9: {
    positive: [
      "Broad-minded with a strong sense of justice",
      "Highly creative with talents in art and design",
      "Charismatic and often surrounded by supportive people",
    ],
    constructive: [
      "Holds onto past grievances, struggles with forgiveness",
      "Prone to idealism, may underestimate practical limitations",
      "Needs to focus energy on productive tasks rather than dwelling on negativity",
    ],
  },
  10: {
    positive: [
      "Self-sufficient and confident with strong leadership skills",
      "Determined and purposeful, with clear principles",
      "Capable of managing people effectively, even in challenging situations",
    ],
    constructive: [
      "Struggles with routine, easily bored without constant excitement",
      "Can be stubborn and resistant to change",
      "Expressive with emotions, may be seen as overly competitive",
    ],
  },
  11: {
    positive: [
      "Highly intuitive and perceptive, often senses others' true motives",
      "Inspirational and capable of motivating others with their ideas",
      "Consistent and strives for perfection in everything they do",
    ],
    constructive: [
      "Overly sensitive to criticism, needs to work on self-esteem",
      "Struggles with financial decisions due to emotional influences",
      "Tends to dream more than take practical action",
    ],
  },
  12: {
    positive: [
      "Creatively gifted with strong problem-solving skills",
      "Energetic and persuasive, excels in social situations",
      "Enjoys being in the spotlight and has a positive influence on others",
    ],
    constructive: [
      "Easily distracted by mood changes and emotions",
      "May struggle with discipline and staying focused",
      "Prone to losing interest quickly, needs better organization",
    ],
  },
  13: {
    positive: [
      "Reliable and family-oriented, values tradition",
      "Balances creativity with practicality, enjoys working with details",
      "Hardworking and determined, known for their responsibility",
    ],
    constructive: [
      "Can be overly focused on routine, leading to frustration",
      "May struggle with indecision when feeling unsatisfied with work",
      "Needs to balance work with personal time to avoid burnout",
    ],
  },
  14: {
    positive: [
      "Adaptable and versatile, able to handle unexpected changes well.",
      "Strong analytical skills, capable of solving complex problems.",
      "Enjoys exploring new ideas and experiences, constantly learning.",
    ],
    constructive: [
      "Tends to be restless, often seeking constant change and excitement.",
      "Can be indecisive, struggling with making firm commitments.",
      "Prone to overthinking, leading to anxiety or stress.",
    ],
  },
  15: {
    positive: [
      "Artistic potential with a strong creative streak.",
      "Loyal and loving in relationships, values family deeply.",
      "Skilled at finding a balance in difficult situations, a natural peacemaker.",
    ],
    constructive: [
      "May struggle with self-doubt and fluctuating confidence levels.",
      "Prone to overindulgence, whether in food, pleasure, or spending.",
      "Needs to focus on completing tasks rather than getting distracted.",
    ],
  },
  16: {
    positive: [
      "Spiritual and introspective, often seeking deeper meanings in life.",
      "Highly intelligent with a strong ability to learn and adapt.",
      "Capable of profound self-reflection and personal growth.",
    ],
    constructive: [
      "Can be withdrawn or isolated, preferring solitude over socializing.",
      "Struggles with trusting others, may appear distant or aloof.",
      "Prone to being overly critical, both of themselves and others.",
    ],
  },
  17: {
    positive: [
      "Ambitious and driven, sets high goals and works diligently.",
      "Natural leader with a strong sense of purpose and direction.",
      "Charismatic and persuasive, often inspiring others to follow their vision.",
    ],
    constructive: [
      "Can be overly focused on success, leading to workaholism.",
      "May struggle with balancing personal and professional life.",
      "Tends to be inflexible, resisting changes to their plans.",
    ],
  },
  18: {
    positive: [
      "Compassionate and empathetic, cares deeply for others.",
      "Broad-minded and fair, with a strong sense of justice.",
      "Creative and imaginative, often excelling in artistic endeavors.",
    ],
    constructive: [
      "Prone to emotional extremes, can be overly sensitive.",
      "Tends to hold onto past hurts or grievances, struggles with forgiveness.",
      "May become overwhelmed by the problems of others, leading to stress.",
    ],
  },
  19: {
    positive: [
      "Independent and self-reliant, capable of achieving great things alone.",
      "Confident and assertive, with a clear sense of direction.",
      "Innovative thinker, often comes up with original ideas and solutions.",
    ],
    constructive: [
      "Can be stubborn and unwilling to ask for help.",
      "Prone to taking on too much, leading to burnout or stress.",
      "May struggle with authority figures or rigid structures.",
    ],
  },
  20: {
    positive: [
      "Sensitive and intuitive, often able to sense the emotions of others.",
      "Diplomatic and tactful, skilled at resolving conflicts peacefully.",
      "Loyal and devoted, values deep and meaningful relationships.",
    ],
    constructive: [
      "Easily affected by the moods and emotions of others, leading to anxiety.",
      "May struggle with indecisiveness, seeking approval before making decisions.",
      "Prone to self-doubt, needing reassurance and validation frequently.",
    ],
  },
  21: {
    positive: [
      "Creative and expressive, often excelling in arts or communication.",
      "Friendly and sociable, enjoys building strong relationships.",
      "Optimistic and energetic, bringing positivity to those around them.",
    ],
    constructive: [
      "Can be easily distracted, needs to work on focus.",
      "Prone to mood swings, impacting decision-making.",
      "Tends to avoid conflict, leading to unresolved issues.",
    ],
  },
  22: {
    positive: [
      "Visionary thinker with strong planning abilities.",
      "Highly disciplined and determined to achieve goals.",
      "Naturally empathetic, often works towards the betterment of others.",
    ],
    constructive: [
      "Can be overly serious, struggling to relax.",
      "Prone to overworking, leading to burnout.",
      "May become frustrated when plans don't go as expected.",
    ],
  },
  23: {
    positive: [
      "Adaptable and flexible, easily navigates changes.",
      "Charismatic and enjoys social interactions.",
      "Quick thinker, capable of solving problems creatively.",
    ],
    constructive: [
      "Can be restless, always seeking new experiences.",
      "Struggles with commitment due to a desire for freedom.",
      "May appear inconsistent or unreliable at times.",
    ],
  },
  24: {
    positive: [
      "Compassionate and nurturing, values family and relationships.",
      "Reliable and responsible, often seen as a caregiver.",
      "Strong sense of justice, stands up for what they believe in.",
    ],
    constructive: [
      "Prone to taking on others' burdens, leading to stress.",
      "Can be overly protective or controlling in relationships.",
      "May struggle with letting go of past grievances.",
    ],
  },
  25: {
    positive: [
      "Analytical and introspective, enjoys deep thinking.",
      "Highly intuitive, often trusts their instincts.",
      "Independent and self-reliant, values personal freedom.",
    ],
    constructive: [
      "Can be emotionally distant, avoiding vulnerability.",
      "Prone to overthinking, leading to anxiety.",
      "May struggle with expressing emotions openly.",
    ],
  },
  26: {
    positive: [
      "Ambitious and goal-oriented, driven by success.",
      "Practical and resourceful, skilled at problem-solving.",
      "Loyal and dependable, a strong pillar in relationships.",
    ],
    constructive: [
      "Can be overly focused on material success, neglecting personal life.",
      "Tends to be rigid in their views, resistant to change.",
      "May struggle with work-life balance, leading to burnout.",
    ],
  },
  27: {
    positive: [
      "Spiritual and compassionate, values inner growth.",
      "Creative and artistic, often finds joy in self-expression.",
      "Broad-minded and fair, respects different viewpoints.",
    ],
    constructive: [
      "Can be overly idealistic, struggling with practicality.",
      "Prone to emotional ups and downs, affecting stability.",
      "May isolate themselves when overwhelmed by emotions.",
    ],
  },
  28: {
    positive: [
      "Confident and assertive, enjoys taking charge.",
      "Highly motivated, sets ambitious goals and achieves them.",
      "Innovative thinker, not afraid to take risks.",
    ],
    constructive: [
      "Can be domineering, often wants control in situations.",
      "Prone to impatience, especially when others are slow to act.",
      "May struggle with handling criticism or feedback.",
    ],
  },
  29: {
    positive: [
      "Empathetic and understanding, connects deeply with others.",
      "Intuitive and perceptive, often senses the true nature of situations.",
      "Devoted and loyal, values meaningful relationships.",
    ],
    constructive: [
      "Can be overly sensitive, taking things too personally.",
      "Struggles with self-doubt, needs reassurance.",
      "May have difficulty letting go of emotional pain.",
    ],
  },
  30: {
    positive: [
      "Expressive and communicative, enjoys sharing ideas.",
      "Creative and imaginative, often comes up with unique solutions.",
      "Energetic and enthusiastic, inspires those around them.",
    ],
    constructive: [
      "Can be easily distracted, lacks focus at times.",
      "Prone to over-exaggeration or dramatization.",
      "May struggle with consistency and follow-through.",
    ],
  },
  31: {
    positive: [
      "Disciplined and hardworking, achieves success through persistence.",
      "Practical and logical, skilled at making sound decisions.",
      "Reliable and trustworthy, values integrity in all dealings.",
    ],
    constructive: [
      "Can be overly rigid, struggling with flexibility.",
      "Prone to workaholism, neglecting personal needs.",
      "May become frustrated when others don’t meet their high standards.",
    ],
  },
};

export default characteristicsData;

  