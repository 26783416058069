import React, { useState } from 'react';
import DateInput from '../components/DateInput';
import LoShuGrid from '../components/LoShuGrid';
import Rating from '../components/Rating';
import Banner from '../components/Banner';
import GridLayout from '../components/GridLayout';
import logo from '../assets/divinesense-high-resolution-logo.png'; 

const HomePage = () => {
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [resetRatingCounter, setResetRatingCounter] = useState(0);
  const importantMessage = "FREE report and whatsapp chat for limited time!";

  const handleCalculate = (dob, gender) => {
    setDob(dob);
    setGender(gender);
    setShowResults(true);
    setResetRatingCounter((prev) => prev + 1); // Increment counter to trigger reset
  };

  const handleEdit = () => {
    setShowResults(false);
  };

  return (
    <div className="container mx-auto px-4 py-8 flex flex-col items-center">
         <Banner message={importantMessage} />
      <DateInput onCalculate={handleCalculate} onEdit={handleEdit} />
      {showResults && (
        <>
          <div className="mt-4 w-full">
            <LoShuGrid dob={dob} gender={gender} />
          </div>
          {/* <GridLayout /> */}
          <Rating dob={dob} gender={gender} resetCounter={resetRatingCounter} />
        </>
      )}
    </div>
  );
};

export default HomePage;
