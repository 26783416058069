import React from 'react';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-lg mb-6 border border-gray-300">
      <h2 className="text-2xl font-bold mb-4">About Us</h2>
      <p>We believe in learning from experience and Numerology is one such study which gets better with experience. Therefore we seek your feedback and also prefer having meetings so that we can help you find your right path where you can excel and also help you with possible remedies if you are going through a rouch patch in your life.</p>
        <div className="p-6">
            <h3 className="text-2xl font-bold mb-6">
            What Numberology can predict and what it can't? 
            </h3>
            <p class="mb-4">
            At A Divine Sense, we believe that numerology is a tool to help you uncover your inner calling and guide you on your unique journey. We are continually learning and evolving, and we view each date of birth as a powerful imprint that carries a distinct frequency throughout a person’s life.
            </p>
            <p class="mb-4">
            In our understanding, no number is inherently good or bad—it’s all about how we align with the different energies they represent. For instance, while the <b>number 8</b> is often linked with challenges, it’s also a number seen in the charts of many successful individuals who excel in financial acumen and perseverance. On the other hand, the <b>number 5 or 6</b>, typically viewed as favorable, can lead to restlessness or stress if its energy is not balanced effectively.
            </p>
            <p class="mb-4">
            Our mission is to assist you in navigating these frequencies, helping you make sense of your numbers and find harmony in your life. We don’t claim to have all the answers, but we are dedicated to continually refining our insights based on your experiences. Together, we can work towards uncovering your true purpose and achieving a more fulfilling life.
            </p>
            <p>
        <Link to="/contact" className="text-blue-500 hover:underline mt-4 block">
              Get in touch with us for more insights and analysis. Happy healing!
            </Link>
        </p>
        </div>
    </div>
  );
};

export default AboutPage;
