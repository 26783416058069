import React from 'react';
import { Link } from 'react-router-dom'; 
import MenuIcon from './MenuIcon';
import logo from '../assets/logo-divinesense.png';

const Header = () => {
  return (
    <header className="bg-white border-b border-gray-300 shadow-sm py-4">
      <div className="container mx-auto px-4 flex justify-between items-center">
        {/* Clickable Header Text */}
        <Link to="/" className="text-2xl font-bold text-gray-800 hover:text-black">
          {/* Logo Image */}
            <img src={logo} alt="157 Logo" className="w-48 h-auto" />
        </Link>
        {/* Menu Icon for Mobile/Small View */}
        <MenuIcon />
      </div>
    </header>
  );
};

export default Header;
