import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutUs';
import ContactPage from './pages/ContactUs';
import Login from './pages/Login';
import ReactGA from 'react-ga4';
import { UserProvider } from './context/UserContext';
//import { useLocation } from 'react-router-dom';

function App() {
  const location = window.location.pathname;

  useEffect(() => {
    // Send pageview when the route changes
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return (
    <UserProvider>
    <Router>
      <Header />
      <main className="container mx-auto px-4 py-8 flex-grow">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </main>
      <Footer />
    </Router>
    </UserProvider>
  );
}

export default App;
