// Characteristics mapping for driver, connector, and date of birth
export const characteristics = {
    driver: {
      1: ["Leadership qualities", "Ambitious", "Independent thinker"],
      2: ["Creative", "Emotional", "Peacemaker"],
      3: ["Creative and social", "Optimistic", "Expressive"],
      4: ["Practical and grounded", "Reliable", "Organized"],
      5: ["Adventurous and dynamic", "Flexible", "Curious"],
      6: ["Responsible", "Nurturing", "Family-oriented"],
      7: ["Introspective", "Spiritual", "Analytical"],
      8: ["Goal-oriented", "Strong leadership", "Efficient"],
      9: ["Compassionate", "Generous", "Philosophical"]
    },
    driverDetails: {
        1: ["Ambitious, born to lead, extremely competetive, high on energy, doer", 
            "Don't get under the pressure to always perform, don't get distracted, try meditating and increasing your focus"
        ],
        2: ["Born creative, attractive, empathetic, emotional, you like to be treated with utmost respect",
            "You tend to be moody and sometimes over emotional, try keeping you emotions in check"
        ],
        3: ["Optimistic, born to attain knowledge, you tend to share your knowledge with others, a good advisor, giver and generous",
            "People might not like your straight forward nature so you need to be aware of whom you are talking to"
        ],
        4: ["Practical, grounded, reliable and organized",
             "You are also rebellious, you don't like to be controlled and often takes unbeaten path. You, however tend to be extremely anxious. Don't get into any addictions.", 
        ],
        5: ["Adventurous, extremely resourceful, flexible, most balanced of all the numbers and tend to be multi-talented", 
            "You find it difficult to say 'no' to people and therefore end up getting a lot of responsibilities. Don't let your easy going attitude and calmness being taken as laziness"

        ],
        6: ["Family oriented, responsible person, have a great taste, love finer things in life, aesthetic",
            "You love brands and quality things in life, attracted to beautiful things in life"

        ],
        7: ["Introspective, independent, dutiful, spiritual and analytical. Multitude of knowledge and you love to do research on various topics in life", 
            "You find it easy to detach from things and do not over react in loss or in win. However, you need to focus on one thing in life at a time and avoid distractions"

        ],
        8: ["Goal-oriented, efficient, hard working", 
            "Only way you will get success is by being patience and through hardwork, there is no stopping after that. Success comes slow but comes for sure"

        ],
        9: ["Passionate, compassionate, energetic, humanitarian, extremely knowledgeable", 
            "You tend to get angry quickly, you love to talk a lot and would not bend your knees to anyone",
        ]
      },
    connector: {
      1: ["Ambitious", "Independent", "Confident"],
      2: ["Cooperative", "Empathetic", "Balanced"],
      3: ["Enthusiastic", "Imaginative", "Artistic"],
      4: ["Dependable", "Methodical", "Hard-working"],
      5: ["Adaptable", "Energetic", "Quick learner"],
      6: ["Caring", "Supportive", "Responsible"],
      7: ["Thoughtful", "Intuitive", "Reserved"],
      8: ["Assertive", "Focused", "Disciplined"],
      9: ["Compassionate", "Humanitarian", "Inspirational"]
    },
    dob: {
      1: ["Independent", "Strong-willed", "Leader"],
      2: ["Cooperative", "Diplomatic", "Team player"],
      3: ["Creative", "Expressive", "Fun-loving"],
      4: ["Practical", "Hard-working", "Reliable"],
      5: ["Adventurous", "Energetic", "Adaptable"],
      6: ["Caring", "Responsible", "Family-focused"],
      7: ["Analytical", "Introspective", "Spiritual"],
      8: ["Ambitious", "Efficient", "Power-oriented"],
      9: ["Compassionate", "Generous", "Idealistic"],
      10: ["Independent", "Confident", "Leader"],
      11: ["Visionary", "Intuitive", "Highly sensitive"],
      12: ["Creative", "Dynamic", "Expressive"],
      13: ["Practical", "Disciplined", "Stable"],
      14: ["Adaptable", "Versatile", "Quick learner"],
      15: ["Caring", "Artistic", "Nurturing"],
      16: ["Thoughtful", "Intellectual", "Philosophical"],
      17: ["Strong-willed", "Ambitious", "Efficient"],
      18: ["Generous", "Compassionate", "Leader"],
      19: ["Independent", "Determined", "Original"],
      20: ["Empathetic", "Diplomatic", "Supportive"],
      21: ["Creative", "Fun-loving", "Communicative"],
      22: ["Master builder", "Visionary", "Practical"],
      23: ["Adventurous", "Energetic", "Quick thinker"],
      24: ["Nurturing", "Responsible", "Caring"],
      25: ["Analytical", "Spiritual", "Deep thinker"],
      26: ["Goal-oriented", "Efficient", "Leader"],
      27: ["Compassionate", "Humanitarian", "Philosophical"],
      28: ["Independent", "Self-sufficient", "Leader"],
      29: ["Intuitive", "Sensitive", "Idealistic"],
      30: ["Creative", "Joyful", "Expressive"],
      31: ["Practical", "Hard-working", "Ambitious"]
    }
  };


  export const yogCharacteristics = {
        golden: ["Strong mental clarity", "Ability to achieve goals with precision", "Heightened confidence"],
        silver: ["Balance between emotions and intellect", "Excellent problem-solving skills", "Blessed with either inheritance or real estate"],
        success: ["Extreme willpower", "Never say NO attitude", "Your willpower is the reason for your success"]
    };

      // Define characteristics for karmic and master numbers
  export const karmicNumbers = {
    13: ["Indicates hard work and overcoming obstacles", "Focus on persistence and growth"],
    14: ["Represents adaptability and change", "Challenges related to control and freedom"],
    16: ["Symbolizes spiritual awakening and intuition", "Lessons on self-awareness and humility"],
    19: ["Linked to independence and leadership", "Lessons around ego and service to others, particularly the working class"]
  };

  export const masterNumbers = {
    11: ["Intuitive, visionary, and highly sensitive", "Represents spiritual insight and enlightenment"],
    22: ["Master builder, practical and powerful", "Symbolizes turning dreams into reality"]
  };