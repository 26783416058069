import React, { useState } from 'react';
import { auth, googleProvider } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup, signInAnonymously } from 'firebase/auth';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Handle Email/Password Login
  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      alert('Login successful!');
    } catch (error) {
      setError(error.message);
    }
  };

  // Handle Google Login
  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      alert('Google Sign-In successful!');
    } catch (error) {
      setError(error.message);
    }
  };

  // Handle Anonymous Login
  const handleAnonymousLogin = async () => {
    try {
      await signInAnonymously(auth);
      alert('Logged in anonymously!');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-container p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>

      {/* Email/Password Login Section */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-4">Login with Email</h3>
        <form onSubmit={handleEmailLogin} className="space-y-4">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
          <button
            type="submit"
            className="w-full bg-black text-white py-2 rounded-lg hover:bg-gray-800"
          >
            Login with Email
          </button>
        </form>
      </div>

      {/* Divider */}
      <div className="flex items-center my-4">
        <hr className="flex-grow border-gray-300" />
        <span className="px-4 text-gray-500">or</span>
        <hr className="flex-grow border-gray-300" />
      </div>

      {/* Google and Anonymous Login Section */}
      <div className="space-y-4">
        <button
          onClick={handleGoogleLogin}
          className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600"
        >
          Login with Google
        </button>
        <button
          onClick={handleAnonymousLogin}
          className="w-full bg-gray-700 text-white py-2 rounded-lg hover:bg-gray-800"
        >
          Login Anonymously
        </button>
      </div>

      {/* Error Message */}
      {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
    </div>
  );
};

export default Login;
