import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = ({ dob, gender }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !email || !message) {
      setError('Please fill in all fields.');
      return;
    }
console.log(gender,dob);
    // Prepare the data to be sent in the email
    const templateParams = {
      name: name,
      email: email,
      message: message,
      dob: dob, // Include Date of Birth
      gender: gender, // Include Gender
    };

    emailjs
      .send(
        'service_6z4khpk',
        'template_hbnyhak',
        templateParams,
        'I0RO-4t4p15ZOiyQQ'
      )
      .then(
        (response) => {
          console.log('Message sent successfully!', response);
          setSuccessMessage('Your message has been sent successfully!');
          setName('');
          setEmail('');
          setMessage('');
          setError('');
        },
        (error) => {
          console.error('Message failed to send:', error);
          setError('Failed to send message. Please try again later.');
        }
      );
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        type="text"
        placeholder="Your Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="w-full p-3 border border-gray-300 rounded-lg"
      />
      <input
        type="email"
        placeholder="Your Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full p-3 border border-gray-300 rounded-lg"
      />
      <textarea
        placeholder="Your Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="w-full p-3 border border-gray-300 rounded-lg"
        rows="4"
      />
      <button
        type="submit"
        className="w-full bg-black text-white py-3 rounded-lg hover:bg-gray-800"
      >
        Send Message
      </button>

      {successMessage && <p className="mt-4 text-green-600">{successMessage}</p>}
      {error && <p className="mt-4 text-red-600">{error}</p>}
    </form>
  );
};

export default ContactForm;
