import React from 'react';
import { calculateKuaNumber } from './CalculateKuaNumber'; // Import the Kua number calculation function
import { karmicNumbers, masterNumbers, characteristics, yogCharacteristics } from '../constants/MainConstants';
import characteristicsData from '../constants/DayCharacter';
import { Link } from 'react-router-dom';
import html2pdf from 'html2pdf.js';

const LoShuGrid = ({ dob, gender }) => {
    const handleDownloadPDF = () => {
        const element = document.getElementById('results-section');

        // Format the filename using the user's date of birth
        const formattedDOB = dob.replaceAll('/', '-').replaceAll('-', '_');
        const filename = `Numerology_Results_${formattedDOB}.pdf`;

        const options = {
            margin: 1,
            filename: filename,
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        // Generate the PDF with a custom header
    html2pdf()
    .set(options)
    .from(element)
    .toPdf()
    .get('pdf')
    .then((pdf) => {
      // Add header text directly using jsPDF API
      pdf.setFontSize(14);
      pdf.setTextColor(30, 30, 30);
      pdf.text('157 Numerology | https://adivinesense.com', 15, 15);
    })
    .save();
    };

    const calculateLoShuGrid = (dob) => {
        const digits = dob.replace(/-/g, '').split('').map(Number);
        const grid = Array(9).fill(0);

        const driverNumber = calculateDriverNumber(dob);
        const connectorNumber = calculateConnectorNumber(dob);
        const kuaNumber = calculateKuaNumber(dob, gender);

        // Add the driver, connector, and kua numbers to the grid for display purposes
        digits.push(driverNumber, connectorNumber, kuaNumber);

        return { grid, applicableNumbers: new Set(digits) };
    };

    const calculateDriverNumber = (dob) => {
        const day = parseInt(dob.split('-')[2], 10);
        return day % 9 === 0 ? 9 : day % 9;
    };

    const calculateConnectorNumber = (dob) => {
        const sum = dob.replace(/-/g, '').split('').reduce((acc, num) => acc + parseInt(num, 10), 0);
        return sum % 9 === 0 ? 9 : sum % 9;
    };

    const { applicableNumbers } = calculateLoShuGrid(dob);

    // Check for specific yogs based on rows, columns, and diagonals
    const hasYog = (numbers) => numbers.every((num) => applicableNumbers.has(num));

    const goldenYog = hasYog([4, 5, 6]);
    const silverYog = hasYog([2, 5, 8]);
    const successYog = hasYog([9, 5, 1]);


    // Add more yog checks as needed

    const driverNumber = calculateDriverNumber(dob);
    const connectorNumber = calculateConnectorNumber(dob);
    const dayOfBirth = parseInt(dob.split('-')[2], 10);
    const dayCharacter = characteristicsData[dayOfBirth] || {};

    const isKarmic = karmicNumbers[dayOfBirth];
    const isMaster = masterNumbers[dayOfBirth];

    return (
        <div className="p-6 bg-white rounded-lg shadow-lg mb-6 border border-gray-300">
            <h3 className="text-2xl font-bold mb-4 text-gray-800">Numerology Details</h3>
            <div className="mt-4" id="results-section">
                {/* Hidden Header for PDF */}
                <div className="pdf-header">
                    <h2>157 Numerology</h2>
                    <p>https://adivinesense.com</p>
                </div>
                <div className="mt-4 p-4 bg-gray-100 rounded-lg border border-black-300 rounded-lg shadow-lg">
                    <h4 className="text-lg font-semibold text-gray-800 mb-2">
                        Your driver number ({driverNumber}):
                    </h4>
                    <ul className="list-disc list-inside ml-4 text-gray-700 mt-2">
                        {characteristics.driverDetails[driverNumber]?.map((trait, index) => (
                            <li key={index}>{trait}</li>
                        ))}
                    </ul>
                </div>
                <div className="mt-4 p-4 bg-gray-100 rounded-lg border border-black-300 rounded-lg shadow-lg">
                    <h4 className="text-lg font-semibold text-gray-800 mb-2">
                        Your lifepath number ({connectorNumber}):
                    </h4>
                    <ul className="list-disc list-inside ml-4 text-gray-700 mt-2">
                        {characteristics.connector[connectorNumber]?.map((trait, index) => (
                            <li key={index}>{trait}</li>
                        ))}
                    </ul>
                </div>

                <div className="mt-4 p-4 bg-blue-100 rounded-lg border border-black-300 rounded-lg shadow-lg">
                    <h3 className="text-xl font-bold mb-4">Unique Characteristics for day {dayOfBirth}</h3>
                    {dayCharacter.positive ? (
                        <>
                            <h4 className="text-lg font-semibold">Positive Traits:</h4>
                            <ul className="list-disc ml-6 mb-4">
                                {dayCharacter.positive.map((trait, index) => (
                                    <li key={index}>{trait}</li>
                                ))}
                            </ul>

                            <h4 className="text-lg font-semibold">Constructive Traits:</h4>
                            <ul className="list-disc ml-6">
                                {dayCharacter.constructive.map((trait, index) => (
                                    <li key={index}>{trait}</li>
                                ))}
                            </ul>
                            <Link to="/contact" className="text-blue-500 hover:underline mt-4 block">
                                Want to know more? Get in touch now via whatsapp or email
                            </Link>
                        </>
                    ) : (
                        <p>No specific characteristics found for this day.</p>
                    )}
                </div>
                {/* Yog Characteristics Section */}
            {goldenYog && (
                <div className="mt-6 p-4 bg-green-100 rounded-lg border border-green-300">
                    <h4 className="text-lg font-bold text-green-800">Golden Yog Detected (4, 5, 6)</h4>
                    <ul className="list-disc list-inside text-green-700 mt-2">
                        {yogCharacteristics.golden.map((trait, index) => (
                            <li key={index}>{trait}</li>
                        ))}
                    </ul>
                </div>
            )}
            {silverYog && (
                <div className="mt-6 p-4 bg-gray-100 rounded-lg border border-gray-300">
                    <h4 className="text-lg font-bold text-gray-800">Silver Yog Detected (2, 5, 8)</h4>
                    <ul className="list-disc list-inside text-gray-700 mt-2">
                        {yogCharacteristics.silver.map((trait, index) => (
                            <li key={index}>{trait}</li>
                        ))}
                    </ul>
                </div>
            )}
            {successYog && (
                <div className="mt-6 p-4 bg-blue-100 rounded-lg border border-blue-300">
                    <h4 className="text-lg font-bold text-blue-800">Success Yog Detected (9, 5, 1)</h4>
                    <ul className="list-disc list-inside text-blue-700 mt-2">
                        {yogCharacteristics.success.map((trait, index) => (
                            <li key={index}>{trait}</li>
                        ))}
                    </ul>
                </div>
            )}
            {/* Karmic Number Section */}
            {isKarmic && (
                <div className="mt-6 p-4 bg-yellow-100 rounded-lg border border-yellow-300">
                    <h4 className="text-lg font-bold text-yellow-800">You have a Karmic number: {dayOfBirth}</h4>
                    <ul className="list-disc list-inside text-yellow-700 mt-2">
                        {karmicNumbers[dayOfBirth].map((detail, index) => (
                            <li key={index}>{detail}</li>
                        ))}
                    </ul>
                    <p> <Link to="/contact" className="text-blue-500 hover:underline mt-4 block">
                        Remedies of karmic debt? Please contact us via whatsapp or email
                    </Link></p>
                </div>
            )}
            </div>
            

            <button
                onClick={handleDownloadPDF}
                className="mt-4 bg-black text-white py-2 px-4 rounded hover:bg-gray-800"
            >
                Download Results as PDF
            </button>

        </div>
    );
};

export default LoShuGrid;
