export const calculateKuaNumber = (dob, gender) => {
    const year = parseInt(dob.split('-')[0], 10);
    const yearSum = year.toString().split('').reduce((acc, num) => acc + parseInt(num, 10), 0);
    const reducedYear = yearSum % 9 === 0 ? 9 : yearSum % 9;
  
    if (gender === 'male') {
      let kua = 11 - reducedYear;
      return kua > 9 ? kua - 9 : kua;
    } else {
      let kua = reducedYear + 4;
      return kua > 9 ? kua - 9 : kua;
    }
  };
  