import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut, onAuthStateChanged } from 'firebase/auth';

const MenuIcon = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  // Toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  // Handle user logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      alert('You have been logged out.');
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error.message);
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      {/* Menu Icon */}
      <button onClick={toggleMenu} className="hover:text-white p-2">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isMenuOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
          <ul className="flex flex-col p-2 space-y-1">
            <li>
              <Link to="/" className="block p-2 text-gray-700 hover:bg-gray-200 rounded" onClick={toggleMenu}>Home</Link>
            </li>
            <li>
              <Link to="/about" className="block p-2 text-gray-700 hover:bg-gray-200 rounded" onClick={toggleMenu}>About Us</Link>
            </li>
            {/* Uncomment these links when you have content for Vision and Services */}
            {/* <li>
              <Link to="/vision" className="block p-2 text-gray-700 hover:bg-gray-200 rounded" onClick={toggleMenu}>Vision</Link>
            </li>
            <li>
              <Link to="/services" className="block p-2 text-gray-700 hover:bg-gray-200 rounded" onClick={toggleMenu}>Services</Link>
            </li> */}
            <li>
              <Link to="/contact" className="block p-2 text-gray-700 hover:bg-gray-200 rounded" onClick={toggleMenu}>Contact Us</Link>
            </li>
            <li>
              {user ? (
                <button
                  onClick={handleLogout}
                  className="block p-2 text-gray-700 hover:bg-gray-200 rounded"
                >
                  Logout {user.displayName || 'User'}
                </button>
              ) : (
                <Link to="/login" className="block p-2 text-gray-700 hover:bg-gray-200 rounded" onClick={toggleMenu}>
                  Login
                </Link>
              )}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MenuIcon;
