import React from 'react';

const Banner = ({ message }) => {
  return (
    <div className="banner bg-black text-white py-2 px-4 text-center mb-4">
      <p>{message}</p>
    </div>
  );
};

export default Banner;
