import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');

  return (
    <UserContext.Provider value={{ dob, setDob, gender, setGender }}>
      {children}
    </UserContext.Provider>
  );
};
