import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Rating = ({ dob, gender, reset }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);

  // Reset the component state when `reset` prop changes
  useEffect(() => {
    setRating(0);
    setComment('');
    setIsSubmitted(false);
    setShowFeedbackInput(false);
  }, [reset]);

  const handleRatingClick = (value) => {
    setRating(value);
    setShowFeedbackInput(value <= 3);
  };

  const handleFeedbackSubmit = async () => {
    if (!comment && rating <= 3) return;

    const feedbackData = {
      rating,
      dob,
      gender,
      comment: comment || '',
      timestamp: new Date(),
    };

    try {
      await addDoc(collection(db, 'ratings'), feedbackData);
      setIsSubmitted(true);
      console.log('Feedback submitted successfully:', feedbackData);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <div className="w-full mt-8 p-4 bg-gray-100 rounded-lg shadow-md">
      <h3 className="text-xl font-bold mb-4">Rate Your Experience</h3>
      {!isSubmitted ? (
        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            {[1, 2, 3, 4, 5].map((value) => (
              <span
                key={value}
                onClick={() => handleRatingClick(value)}
                className={`cursor-pointer text-3xl ${value <= rating ? 'text-yellow-500' : 'text-gray-400'}`}
              >
                ★
              </span>
            ))}
          </div>

          {showFeedbackInput && (
            <div className="mt-4">
              <label className="block text-black mb-2">Please let us know how we can improve:</label>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Please let us know what went wrong"
                className="border rounded p-2 w-full bg-gray-100 text-black"
                rows={4}
              ></textarea>
            </div>
          )}

          <button
            onClick={handleFeedbackSubmit}
            className="mt-4 bg-black text-white py-2 px-4 rounded hover:bg-gray-800"
            disabled={rating === 0 || (rating <= 3 && !comment)}
          >
            Submit Feedback
          </button>
        </div>
      ) : (
        <div className="text-green-600">
          <p>Thank you for your feedback!</p>
          {rating <= 3 ? (
            <Link to="/about" className="text-blue-500 hover:underline mt-4 block">
              Learn more about us and our definition of Numberology
            </Link>
          ) : (
            <Link to="/contact" className="text-blue-500 hover:underline mt-4 block">
              Get in touch with us for further analysis
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default Rating;
