import React, { useState, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const DateInput = ({ onCalculate }) => {
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('male'); // Default gender set to "male"
  const { setDob: setContextDob, setGender: setContextGender } = useContext(UserContext);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setContextDob(dob);  // Update context with dob
    setContextGender(gender);  // Update context with gender
    // Save DOB to Firestore
    try {
      await addDoc(collection(db, 'dobCollection'), {
        dob: dob,
        gender: gender,
        timestamp: new Date(),
      });
      console.log('DOB successfully saved to Firestore.');
    } catch (error) {
      console.error('Error saving DOB to Firestore:', error);
    }

    // Proceed with the calculation
    onCalculate(dob, gender);
    setIsSubmitted(true);
  };

  const handleEdit = () => {
    setIsSubmitted(false);
  };

  return (
    <div className="w-full p-4 rounded-lg border border-gray-300">
      {!isSubmitted ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700">Date of Birth:</label>
            <input
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              required
              className="border rounded p-2 w-full"
            />
          </div>
          <div>
            <label className="block text-gray-700">Gender:</label>
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
              className="border rounded p-2 w-full"
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
          <button type="submit" className="bg-black text-white py-2 px-4 rounded hover:bg-gray-800">
            Calculate
          </button>
        </form>
      ) : (
        <div className="flex items-center justify-between w-full">
          <p>
            <strong>DOB:</strong> {dob} | <strong>Gender:</strong> {gender === 'male' ? 'Male' : 'Female'}
          </p>
          <button onClick={handleEdit} className="ml-4 bg-black text-white py-1 px-3 rounded hover:bg-gray-800">
            Edit
          </button>
        </div>
      )}
    </div>
  );
};

export default DateInput;
