import React , { useContext } from 'react';
import ContactForm from '../components/ContactForm';
import Banner from '../components/Banner';
import { UserContext } from '../context/UserContext';
import ScheduleCall from '../components/ScheduleCall';
import WhatsAppButton from '../components/WhatsAppButton';

const ContactPage = () => {
    const { dob, gender } = useContext(UserContext);
    const bannerMessage = "We have a long pending request for the reports so kindly bear with us. We will definitely get back to you.";
console.log("DOB"+ dob, gender);
    return (
        <div className="p-6 bg-white rounded-lg shadow-lg mb-6 border border-gray-300">
             <Banner message={bannerMessage} />
            {/* WhatsApp Button */}
            {/* <WhatsAppButton /> */}

            {/* Divider */}
            {/* <div className="flex items-center my-4">
                <hr className="flex-grow border-gray-300" />
                <span className="px-4 text-gray-500">or</span>
                <hr className="flex-grow border-gray-300" />
            </div> */}

            {/* Contact Form */}
            <ContactForm dob={dob} gender={gender} />
            {/* <ScheduleCall /> */}
        </div>
    );
};

export default ContactPage;
